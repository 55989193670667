.create-bar-container{
    padding-top: 16px;
    padding-bottom: 24px;
    max-width: 700px;
    margin-left: auto;
    margin-right: auto;
   
}

.create-bar{
    /* margin: 0 auto !important; */
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
    border-radius: 10px;

    height: fit-content !important;
    width: 705px !important;
    padding: 5px !important;
    background: white;
    /* border: 1px solid gray; */
     
}


.create-bar-input{
    border: 0px !important;
    height: 40px !important;
    width: 650px;
    margin-top: 16px !important;
    margin-bottom: 8px !important;
    color: black !important;
    opacity: 1;
   
}

.create-bar-input:focus{
    outline-style: none !important;
    outline-color: transparent;
    box-shadow: none !important;
}

.create-bar-text{
    border: 0px !important;
    color: black !important;
    opacity: 1;
    resize: none;
   
}

.create-bar-text::-webkit-scrollbar{
    display: none;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.create-bar-text:focus{
    outline-style: none !important;
    outline-color: transparent;
    box-shadow: none !important;
}


.create-bar:hover{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    cursor: pointer;
    border-radius: 10px;
}

.create-bar:focus-within{
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.create-note-button{
    margin: 8px;
    border-radius: 5px !important;

}

@media screen and (max-width: 800px){
    .create-bar-container{
        width: 100% !important;
        margin-left: auto;
        margin-right: auto;
        max-width: 320px !important;;
        padding-top: 0px;
    }

    .create-bar{
        width: 100% !important;
        
        
    }

    .create-bar-input{
        width: 250px !important;
        font-size: 1.1rem !important;
    }


    .create-note-button{
        font-size: 14px !important;
    }
}

.fa-thin-2{
    -webkit-text-stroke: 2.0px white !important;
  }