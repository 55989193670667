.sign-page{
    margin-top: -80px;
    padding-top: 40px;
    width:100% !important;
    height: 100% !important;
    background-image: url("grey-background.png") !important;
    max-width: 100% !important;
}

.sign-page-dark{
    margin-top: -80px;
    padding-top: 40px;
    width:100% !important;
    height: 100% !important;
    max-width: 100% !important;
}

.sign-container{
    padding-top: 50px;
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: 600px;
    max-width: 800px !important;
    background: white;
    border-radius: 15px;
    box-shadow: 0px 1px 10px rgba(0,0,0,0.1);
    
}

.sign-up-container{
    padding-top: 60px;
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 60px;
    margin-bottom: 60px;
    width: 100%;
    height: auto;
    max-width: 800px !important;
    background: white;
    border-radius: 15px;
    box-shadow: 0px 1px 10px rgba(0,0,0,0.1);

    
}

.sign-title{
    color: black;
    font-size: 29px;
    font-weight: 400;
    letter-spacing: 0.7px;
    margin-bottom: 16px;
}

#route-text{
    margin-bottom: 50px;
}

.forgot-password{
    color: rgb(165, 165, 165) !important;
    max-width: 300px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 32px;
}
.route-link{
    color: rgb(165, 165, 165) !important;
   
}

.sign-input{ 
    max-width: 320px;
    margin-left: auto;
    margin-right: auto;

}

.sign-input::placeholder{
    color: rgb(207, 207, 207);
    font-weight: 400;
    font-size: 18px;
}

.sign-button{
    display: block !important;
    background-color: rgb(49, 49, 49) !important;
    color: white !important;
    margin-top: 10px;
    margin-left: auto !important;
    margin-right: auto !important;
    width: 100% !important; 
    max-width: 320px;
    height: 44px;
    transition: background-color 0.1s ease-in-out 0s;
}


.social-icon{
    padding: 15px;
    width: 100%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    font-size: 27px !important;
}

.fa-facebook-f{
    color: rgb(59, 89, 152) !important;
}

.fa-google{
    color: #dd4b39
}

.signin-social{
    /* display: inline-block; */
    margin-top: 16px;
    border-radius: 50px;
    width: 100%;
    max-width: 60px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 24px;
}

.signin-social:hover{
    cursor: pointer
}

.social{
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    max-width: 170px;

}

.social-title{
    margin-top: 32px;
    color: rgb(165, 165, 165) !important;
    margin-bottom: 12px;
    font-weight: bold;
}

.error-message{
    width: 100%;
    text-align: center;
    margin-top: 32px;
}

.terms-section{
    font-size: 14px;
    width: 300px;
    margin: auto;
    color: rgb(49, 49, 49);
    text-align: center;
}


@media screen and (max-width: 1200px){

    .text-center{
        font-size: 24px;
    }

    .sign-container, .sign-up-container{
        max-width: 500px !important;
        
    }

}

@media screen and (max-width: 1000px){

    .sign-container, .sign-up-container{
        max-width: 500px !important;
        
    }

}

@media screen and (max-width: 650){
    .text-center{
        font-size: 20px;
    }


    .sign-container, .sign-up-container{
        margin-top: 0px;
        padding-top: 40px;
        max-width: 300px !important;
     
    }

}

@media screen and (max-width: 600px){
    .text-center{
        font-size: 20px;
    }

    .sign-page{
        padding-top: 70px;
    }

    .terms-section{
        width: 250px;
    }
    .sign-container{
        margin-top: 40px;
        padding-top: 40px;
        max-width: 330px !important;
        height: 508px;
 
    }

    .sign-up-container{
        margin-top: 0px;
        padding-top: 40px;
        max-width: 330px !important;
        height: auto;
 
    }

    .sign-input, .sign-button{
        max-width: 280px;
    }

    .sign-input::placeholder{
        font-size: 16px;

    }

}
