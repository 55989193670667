@media screen and (min-width: 1700px){
    #container{
        max-width: 1700px !important;
        /* margin-right: auto;
        margin-left: auto; */
              /* Firefox */
      -moz-transition: all 0.5s ease-in;
      /* WebKit */
      -webkit-transition: all 0.5s ease-in;
      /* Opera */
      -o-transition: all 0.5s ease-in;
      /* Standard */
      transition: all 0.5s ease-in;
    }
}

.page-title{
    margin-left: 74px;
    margin-bottom: 32px;
    margin-top: 32px;
    /* margin-left: auto;
    margin-right: auto; */
    max-width: fit-content;
    color: rgb(119, 119, 119);
    text-transform: uppercase;
    font-size: 21px;
}
.empty-message{
    margin: auto;
    margin-top: 62px;
    text-align: center;
    width: fit-content;
}

@media screen and (min-width: 1380px) and (max-width: 1699px){
    .page-title{
        margin-left: 30px;
    }
}

@media screen and (max-width: 1380px){
    .page-title{
        margin-left: auto;
        margin-right: auto;
        max-width: fit-content;
    }
}

@media screen and (min-width: 1700px) and (max-width: 1900px){
    #container{
        max-width: 1300px !important;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1380px){
    .container{
        max-width: 960px !important;

    }
}